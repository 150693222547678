import React from "react";
import "../Style/CareBussiness.css";
import { Link } from "react-router-dom";
// import img1 from "../../src/assets/img/careimg1.png";
// import img2 from "../../src/assets/img/careimg2.jpg";
// import img3 from "../../src/assets/img/careimge3.jpg";
// import img4 from "../../src/assets/img/careimg4.jpg";
import img8 from "../../src/assets/img/image 87.png";
import img9 from "../../src/assets/img/Group 1261155856 1.png";
import img10 from "../../src/assets/img/Rectangle 39715.png";
import img11 from "../../src/assets/img/Rectangle 39717.png";
import img12 from "../../src/assets/img/uncheck.svg";
import img13 from "../../src/assets/img/Rectangle 39716.png";
import Navbar from "../Components/Layout/Navbar";
import Footer from "../Components/Layout/Footer";

function BussinessBanner() {
  const indicard2data = [
    {
      title: "Productivity Guarantee",
      image: "./images/careimg4.jpg",
      description: "By empowering your employees and helping them manage their physical, mental health and well-being. It allows them to focus their efforts on what they work on it. Ensure wellness at work and at home."
    },
    {
      title: "Employee Stability And Success",
      image: "./images/careimge3.jpg",
      description: "Retaining talents and creating an attractive environment for them within the organizations.and achieving job satisfaction."
    },
    {
      title: "Enhance The Organization's Image",
      image: "./images/careimg2.jpg",
      description: "The desire of employees to remain in the organization and their loyalty to it or your desire to attract good competencies require a health-promoting environment"
    },
    {
      title: "Reduce Health Care Costs",
      image: "./images/careimg1.png",
      description: "Which consedred as one of the most important goals of the health promotion program for organizations."
    },

  ];
  return (
    <>
      <Navbar />
      <section className="cb-banner">
        <div className="container py-5">
          <div className=" d-flex justify-content-between">
            <div className="">
              <h1 className="zw_56 poppins-extrabold text_gradient" >
                Zuwara Business
              </h1>
              <p className="poppins-semibold zw_46 text_gradient" style={{lineHeight: '69px'}} >
                Your partner to enhance your employee&#39;s health and
                well-being
              </p>
              <p className="poppins-regular zw_16 zw_text_color" >
                Medical solutions for companies that aim to provide medical
                services to their employee to maintain and improve their health
                by providing wide coverage to allow them obtain medical services
                anywhere and at any time.
              </p>
              <div>
                <Link to="/contactus">
                  <div className="container-12">
                    <div className="my-4">
                      <button className="px-5 py-3 poppins-medium zw_text_fff zw_bg_gradient zw_18" style={{ border: "none", borderRadius: "5px", fontFamily: "Poppins, 'Source Sans Pro'" }}>
                        Contact Us
                      </button>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="mt-5">
                <img src={img8} alt="" className="ellipseim" />
                <span className="poppins-regular zw_16 zw_text_color mx-3" >
                  {" "}
                  A platform approved by the Saudi Ministry of health
                </span>
              </div>
            </div>
            <div className="align-self-center">
              <img src={img9} alt="" className="banner_img_size" />
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container ">
          <div className="row">
            <div className="col text-center">
              <h1 className="mt-5 poppins-bold zw_46 text_gradient" >
                Why Zuwarh Business
              </h1>
            </div>
          </div>
          <div className="row my-3">
            <div className="col text-center">
              <p className="poppins-regular zw_18 " >
                We support you in apply occupational safety and health to
                enhance loyality of all of your employees
              </p>
            </div>
          </div>
          <section className="">
            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                {/* <!-- Card  --> */}
                {indicard2data.map((indiitem2) => {
                  return (
                    <div className=" my-4">
                      <div className="card get-size-2 gap-3">
                        <img className="iv-drip-2" src={indiitem2.image} />
                        <div className="">
                          <p className=" poppins-bold zw_22 zw_text_color">

                            {indiitem2.title}
                          </p>
                          <p className="poppins-regular zw_18">

                            {indiitem2.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="custom-section">
        <div
          className="container px-4 py-4 rounded"
          style={{ backgroundColor: "#F6F7F9" }}
        >
          <div className=" busines-page">
            <div className="images-column">
              <div>
                <img src={img10} alt="" className="cardb_img_size" />
              </div>
              <div className="mt-5">
                <img src={img13} alt="" className="cardb_img_size" />
              </div>
            </div>
            <div className="content-column mx-5">
              <h1 className="poppins-semibold zwaara_title" >
                Our programs for companies and groups
              </h1>
              <p className="poppins-regular zw_16 zw_secondary my-3" >
                Health setbacks do not only affect individuals, but also in the
                companies they work, insurance costs rise, morale declines,
                productivity decreases, and others. Therefore, our solutions are
                part of today's business world, which is accelerating and new
                things have been imposed on employers to take more care and
                attention for their employee.
              </p>
              <ul className="mt-4 p-0">
                <li className="my-2">
                  <img src={img12} alt="Icon 2" />
                  <span className="mx-4 zw_16 zw_002a56 poppins-regular" >
                    Medical and psychological counseling
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins-regular" >
                    Chronic Disease Management Program
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins-regular" >
                    Routine tests package for employees
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins-regular" >
                    Seasonal vaccinations for employees
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins-regular" >
                    Manage Health Certificate Reservations (Baladi)
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins-regular" >
                    Hajj package for Hajj campaigns
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins-regular" >
                    Pregnancy follow-up programme
                  </span>
                </li>
                <li className="my-2">
                  <img src={img12} alt="Icon 1" />
                  <span className="mx-4 zw_16 zw_002a56 poppins-regular" >
                    And more...
                  </span>
                </li>
              </ul>
            </div>
            <div className="banner-column">
              <div>
                <img src={img11} alt="" className="cardbt_img_size" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-5">
        <div className="container cb-banner py-5">
          <div className="row">
            <div className="col text-center py-5">
              <h1 className="zw_24 poppins-bold text_gradient my-3 py-3" >
                Take care of your employee health
              </h1>
              <p className="poppins-regular zw_16 zw_black my-4" >
                And join to the leading companies to ensure wellness and
                prosperity at work and in the workplace
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default BussinessBanner;
