import React, { useState } from 'react';
import moment from 'moment';
import '../../Style/Login_step4.css';
import '../../Style/login_popup.css';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Datepicker } from "@mobiscroll/react";

function Accountsetup_Bdate({ handleSlectedDate }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedCalendar, setSelectedCalendar] = useState('gregorian');

    const handleSet = (event) => {
        const date = event.value;
        setSelectedDate(date);
    };

    const toggleCalendar = (calendarType) => {
        setSelectedCalendar(calendarType);
    };

    const formatDate = (date) => {
        return moment(date).format('DD-MM-YYYY');
    };

    return (
        <div className='container my-5'>
            <span className='poppins-semibold zw_24 zw_text_color'>Select Date of Birth</span>
            
            <div className='container Date_type_bg' style={{ padding: "10px", display: "flex", justifyContent: "space-between" }}>
                <button
                    className={`btnn poppins-medium zw_20 zw_title_color py-2 ${selectedCalendar === 'gregorian' ? 'active' : ''}`}
                    style={{ width: "40%" }}
                    onClick={() => toggleCalendar('gregorian')}
                >
                    Gregorian
                </button>
                <button
                    className={`btnn poppins-medium zw_20 zw_title_color py-2 ${selectedCalendar === 'hijri' ? 'active' : ''}`}
                    style={{ width: "40%" }}
                    onClick={() => toggleCalendar('hijri')}
                >
                    Hijri
                </button>
            </div>
            <div className='datepickerCont'>
                <Datepicker
                    controls={['date']}
                    display="inline"
                    touchUi={true}
                    dateFormat="D MMMM YYYY"
                    onChange={handleSet}
                    max={new Date()} 
                />
            </div>
            <div className='container mb-4' style={{ display: "flex", justifyContent: "center" }}>
                <button
                    type='button'
                    className='link_btn'
                    style={{ width: "60%", border: 'none' }}
                    onClick={() => handleSlectedDate(formatDate(selectedDate))}
                >
                    Select
                </button>
            </div>
        </div>
    );
}

export default Accountsetup_Bdate;
