import React, { useState, useContext, useEffect } from "react";
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";
import { Link, useNavigate } from "react-router-dom";
import v1 from "../../images/seasonalflu.png";
import close from "../../images/close.svg";
import "../../Style/VaccinationList.css";
import { Context } from '../../Context';
import MapLocationPop from '../MapLocationPop';

function Seasonalflu() {
  // const packages = [
  //   {
  //     id: "MTA55",
  //     name: "Seasonal Flu1",
  //     price: 1300,
  //     details: "Seasonal Flu vaccines are recommended by MOH, to protect against seasonal influenza",
  //   },
  //   {
  //     id: "MTA56",
  //     name: "Seasonal Flu2",
  //     price: 1300,
  //     details: "Seasonal Flu vaccines are recommended by MOH, to protect against seasonal influenza",
  //   },
  //   {
  //     id: "MTA57",
  //     name: "Seasonal Flu3",
  //     price: 1300,
  //     details: "Seasonal Flu vaccines are recommended by MOH, to protect against seasonal influenza",
  //   },
  // ];

  const [selectedVaccinations, setSelectedVaccinations] = useState([]);
  const [counts, setCounts] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const { show, setShow } = useContext(Context);
  const [locationName, setLocationName] = useState('');
  const { appointmentData, updateAppointmentData, addressList } =
    useContext(Context);
  const [zoneData, setZoneData] = useState([]);
  const navigate = useNavigate();

  const fetchZoneData = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://zuwara.net/admin/public/api/fetchZoneData/${latitude}/${longitude}`,
        {
          headers: {
            "Content-Type": "application/json",
            Cookie: "zwarra_session=8svaEnKXoPHya4NjfgtmI4XABhWqWjVpkmz53q2L",
          },
        }
      );
      const data = await response.json();
      setZoneData(data); // Set the fetched zone data to state
      console.log("Zone Data:", data); // Log the zone data
    } catch (error) {
      console.error("Error fetching zone data:", error);
    }
  };

  const showLocation = (loc) => {
    setLocationName(loc);
    updateAppointmentData({ Address: loc });
    const latitude = 45.07187238118124;
    const longitude = 26.286879877969852;
    fetchZoneData(latitude, longitude);
  };

  const handleVaccinationSelection = (e) => {
    const selectedVaccinationId = e.target.getAttribute('data-id');
    const selectedVaccinationLabel = e.target.getAttribute('data-label');

    const isSelected = selectedVaccinations.some(vaccination => vaccination.id === selectedVaccinationId);

    if (isSelected) {
      // If the test is already selected, remove it
      setSelectedVaccinations(prevVaccinations =>
        prevVaccinations.filter(vaccination => vaccination.id !== selectedVaccinationId)
      );
      setCounts(prevCounts => {
        const newCounts = { ...prevCounts };
        delete newCounts[selectedVaccinationId]; // Remove the count associated with this vaccination
        return newCounts;
      });
    } else {
      // If the test is not selected, add it
      setSelectedVaccinations(prevVaccinations => [
        ...prevVaccinations,
        { id: selectedVaccinationId, label: selectedVaccinationLabel }
      ]);
      setCounts(prevCounts => ({
        ...prevCounts,
        [selectedVaccinationId]: 1 // Initialize the count
      }));
    }
  };


  const handleRemoveVaccination = (vaccinationId) => {
    setSelectedVaccinations(
      selectedVaccinations.filter(vaccination => vaccination.id !== vaccinationId)
    );
    setCounts(prevCounts => {
      const newCounts = { ...prevCounts };
      delete newCounts[vaccinationId];
      return newCounts;
    });
  };

  const incrementCount = (vaccinationId) => {
    setCounts(prevCounts => ({
      ...prevCounts,
      [vaccinationId]: (prevCounts[vaccinationId] || 1) + 1
    }));
  };

  const decrementCount = (vaccinationId) => {
    setCounts(prevCounts => ({
      ...prevCounts,
      [vaccinationId]: prevCounts[vaccinationId] > 1 ? prevCounts[vaccinationId] - 1 : 1
    }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // const filteredPackages = packages.filter(packageItem =>
  //   packageItem.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const getPackageImage = (packageId) => {
    switch (packageId) {
      case "MTA55":
      case "MTA56":
      case "MTA57":
        return v1;
      default:
        return null;
    }
  };

  const [SeasonalfluData, setSeasonalfluData] = useState([]);
  useEffect(() => {
    fetch(
      "https://zuwara.net/admin/public/api/subservices?id=15&servicetype=single"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSeasonalfluData(data);
      });
  }, []);
  //   const handleLocationSelect = (selectedLocation) => {
  //     console.log("Selected location:", selectedLocation); 
  //     setLocationName(selectedLocation);
  //     setShow(false);
  //   };

  const handlenext = async (e) => {
    e.preventDefault();
    if (selectedVaccinations.length === 0) return; // Prevent continuation without selection
    console.log("Step 2 data", appointmentData);
    updateAppointmentData({ Subservices: JSON.stringify(selectedVaccinations) });
    navigate("/seasonalflucenter");
  };

  return (
    <div >
      <Navbar />
      <div className="distance vaccination" style={{marginTop: '60px'}}>
        <div className="container-17">
          <div className="group-1261154093">
            <div>
              <Link to={'/'}>
                <div className="d-flex mx-4">
                  <img className="group-1261154072" src="/images/Group 1261154072.png" alt='Group' />
                  <span className="back poppins-medium zw_18 zw_black">
                    Back
                  </span>
                </div>
              </Link>
            </div>
            <div className="line_indicator_container">
              <div className="each_line_indicator active">
              </div>
              <div className="each_line_indicator">
              </div>
              <div className="each_line_indicator">
              </div>
              <div className="each_line_indicator">
              </div>
            </div>
          </div>
          <div className="vaccination-list poppins-semibold zw_34 zw_text_color mx-4">
            Seasonal Flu
          </div>
          <div className="container-13 gap-5">
            <div className="col-xl-5 col-md-8 frames-37119 p-4 mx-4">
              <div className='align-self-center d-flex select-location-hover' onClick={() => setShow(true)}>
                <img className="grouped-1" src="/images/location.png" alt='location' />
                <div className="ms-3 poppins-semibold zw_14 zw_title_color" >Selected Location</div>
              </div>
              <div className="lined-1"></div>
              <div>
                <span className="poppins-semibold zw_14 zw_title_color">{appointmentData.Address}</span>
              </div>
            </div>
            <div className="search-container">
              <form style={{ display: 'flex' }}>
                <input
                  style={{
                    width: "40rem",
                    borderRadius: "5px 0px 0px 5px",
                    padding: "13px",
                  }}
                  type="text"
                  className="search-input zw_16 poppins-regular"
                  placeholder="Search your lab tests & Packages"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <button style={{ borderRadius: "0px 5px 5px 0px " }} type="submit" className="search-button zw_bgwhite">
                  <i className="icon-search zw_black "></i>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="d-flex seasonal-selected justify-content-between mx-5" style={{ padding: '1px 7%' }}>
          <div className="col-lg-7">
            <div className="sa-nurse">
              {SeasonalfluData.length > 0 ? (
                <ul className="sa-card-list sa-nurse-checkbox seanal-card">
                  {SeasonalfluData.map((packageItem) => (
                    <li
                      className="p-3 my-4"
                      key={packageItem.id}
                      style={{
                        borderRadius: "10px",
                        border: "1px dashed #9747FF",
                        position: 'relative'
                      }}
                      id="container-box-flu"
                    >
                      <div
                        className="sa-card-list-box p-5"
                        style={{
                          display: "flex",
                          backgroundColor: "#fff",
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                          borderRadius: "6px",
                          position: 'relative'
                        }}
                      >
                        {/* <figure>
                          {getPackageImage(packageItem.id) && ( */}
                        <img
                          src={`https://zuwara.net/admin/public/${packageItem?.Logo}`}
                          alt="img"
                          style={{
                            height: "130px",
                            width: "105px",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        ></img>
                        {/* )}
                        </figure> */}
                        <div
                          className="sa-doct-det"
                          style={{ marginTop: "0px", marginLeft: "17px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                            className=""
                          >
                            <label
                              htmlFor={packageItem.id}
                              className="poppins-bold zw_24 zw_text_color"
                            >
                              {packageItem.Enname}
                            </label>
                          </div>
                          <p
                            className="poppins-regular zw_16"
                            style={{
                              margin: "10px 0 10px 0",
                              color: "#898B9F",
                            }}
                          >
                            {`${packageItem.Endescription}. starting from ${packageItem.Price} SAR`}
                          </p>
                        </div>
                      </div>
                      <div
                        className="select_boxx"
                        data-id={packageItem.id}
                        data-label={packageItem.Enname}
                        name="Vitamin IV Drips"
                        value={packageItem.id}
                        onClick={handleVaccinationSelection}
                        style={{
                          backgroundColor: selectedVaccinations.some(
                            (vaccination) => String(vaccination.id) === String(packageItem.id)
                          )
                            ? "#af2245"
                            : "white",
                        }}
                      ></div>


                    </li>
                  ))}
                </ul>
              ) : searchTerm.length > 0 ? (
                <div className="no-result-found-msg poppins-semibold zw_18">
                  No results found.
                </div>
              ) : null}
            </div>
          </div>
          <div className='ms-3'>
            <div className="selected-test poppins-semibold zw_16 zw_text_color">
              Selected test
            </div>
            <div className="container-11">
              {selectedVaccinations.map((vaccination) => (
                <div className="frame-1261154252" key={vaccination.id}>
                  <div className="selected-test-details">
                    <div className="poppins-medium zw_12 zw_title_color">
                      {vaccination.label}
                    </div>
                    <div style={{ display: 'flex' }}>
                      <button className='btn-circle' onClick={() => decrementCount(vaccination.id)}>
                        <div className='btn-minus'>-</div>
                      </button>
                      <span className='test-number'>{counts[vaccination.id] || 1}</span>
                      <button className='btn-circle select-box-bg' onClick={() => incrementCount(vaccination.id)}>
                        <div className='btn-plus'>+</div>
                      </button>
                    </div>
                  </div>
                  <button className="component-1" onClick={() => handleRemoveVaccination(vaccination.id)}>
                    <img src={close} alt='' />
                  </button>
                </div>
              ))}
              {/* <Link to={`${selectedVaccinations.length !== 0 ? '/seasonalflucenter' : '#'}`}> */}
              <button className={`frame-37121 poppins-regular zw_14 zw_text_fff ${selectedVaccinations.length === 0 ? 'disabled' : ''}`} onClick={handlenext} style={{ cursor: selectedVaccinations?.length === 0 ? 'not-allowed' : 'pointer' }}>
                Continue
              </button>
              {/* </Link>  */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {show && <MapLocationPop path={"samePage"} locName={showLocation} />}
    </div>
  );
}

export default Seasonalflu;
