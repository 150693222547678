import React, { useState, useRef, useEffect, useContext } from 'react';
import '../../Style/login_popup.css';
import { Context } from '../../Context';
function OTP() {
  const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = useRef([]);
  const { signupFormData } = useContext(Context);
  const { Phone } = signupFormData;

  useEffect(() => {
    // Set focus to the first input field when the component is mounted
    inputRefs.current[0].focus();
  }, []);

  const handleOtpChange = (index, value) => {
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus on the next input field if the current one is filled
      if (index < otp.length - 1 && value !== '') {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      // Focus on the previous input field if Backspace is pressed and the current input is empty
      inputRefs.current[index - 1].focus();
    }
  };

  

  const otpSubmit = (e) => {
    e.preventDefault();
    const combinedOtp = otp.join('');
    console.log('Entered OTP:', combinedOtp);

    // Here you can add OTP verification logic

    const nextModal = new window.bootstrap.Modal(document.getElementById('successs'));
    const currentModal = window.bootstrap.Modal.getInstance(document.getElementById('OTP'));
    currentModal.hide();
    nextModal.show();



    // Set authentication state to true
    // setIsAuthenticated(true);
  };

  return (
    <div className='zw_popup'>
      <div className="modal fade" id="OTP" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered ms-auto" role="document">
          <div className="modal-content">
            <div className="modal-body mb-4">
              <div className="reg-spcv1 regspcv2">
                {/* <div className='' > */}
                <button type="button" className="sps-dialog-close regv2back" data-bs-dismiss="modal" aria-label="Close">
                  {/* <i className="icon-close" ></i> */}
                  <img src="/images/crossicon.png" alt="" style={{ width: "16px" }} />
                </button>
                {/* </div> */}

                <div className="set-up mob-header mt-5 d-flex" style={{ gap: '8rem' }}>
                  {/* <i className="icon-left-arrow f-size18 cursor mob-block"></i> */}
                  {/* <img src="/images/backmark.png" className='ms-5' style={{height: '3rem'}} alt=""/> */}
                  <img
                    src="/images/backmark.png"
                    className="ms-5"
                    style={{ height: '3rem', cursor: 'pointer' }} // Add pointer cursor for better UX
                    alt="Back"
                  />
                  <h6 className='poppins-semibold zw_text_color zw_28 mb-4' style={{ textAlign: "center" }}>Account Setup</h6>
                </div>
                <div className="container ps-5">
                  <p className='poppins-regular zw_24 zw_9B9B9B mb-0'>
                    Step 3 completed of 4
                  </p>
                  <div className='line_indicator_container w-100'>
                    <div className='line-indicator-bg each_line_indicator active'></div>
                    <div className='line-indicator-bg each_line_indicator active'></div>
                    <div className='line-indicator-bg each_line_indicator active'></div>
                    <div className='line-indicator-bg each_line_indicator active'></div>
                  </div>
                </div>
                <div className="container">
                  <div className="form-group verifi-code">
                    <div className="row dir-ltr mx-5">
                      {otp.map((digit, index) => (
                        <div className="col-3 col-md-3 col-sm-3 col-xs-3" key={index}>
                          <input
                            type="tel"
                            placeholder='1'
                            maxLength="1"
                            className="zw_secondary poppins-regular zw_24 zw_form_control text-center plr10 otpfield"
                            name="Otp"
                            value={digit}
                            onChange={(e) => handleOtpChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            ref={(el) => (inputRefs.current[index] = el)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="text-center dir-ltr m-4">
                    <p className="zw_20 poppins-medium zw_text_color verification-link " style={{ marginTop: "-30px" }}>Please type the verification code</p>
                    <span className='zw_20 poppins-semibold zw_text_color'>{Phone}</span>
                  </div>
                  <div className="d-f-end d-flex-dir-col text-center mb-4">
                    <p className="poppins-regular zw_btn_18 zw_title_color resend-otp" style={{ marginBottom: "40px" }}>Didn't receive resend</p>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <button
                      type='button'
                      className='zw_text_fff mt-3 poppins-regular zw_24 zw_bg_gradient zw_btn_18 zw_border_none p-2'
                      onClick={otpSubmit}
                      style={{ width: "340px", height: "55px", borderRadius: '6px' }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
              <div className="reg-spcv3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTP;
