import React, { useState, useEffect, useContext } from "react";
// import countryList from 'react-select-country-list';
import Navbar from "../../Layout/Navbar";
import Footer from "../../Layout/Footer";
import "../../../Style/Profile.css";
import { Link, useNavigate } from "react-router-dom";
import Addrecord from "./Addrecord";
import ReactFlagsSelect from "react-flags-select";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../Context";
// import Navbar from '../../Layout/Navbar';
// import Footer from '../../Layout/Footer';

const defaultImageUrl =
  "https://th.bing.com/th/id/OIP.awAiMS1BCAQ2xS2lcdXGlwAAAA?rs=1&pid=ImgDetMain"; // Replace with your initial image URL

function Profile() {
  const { username, signupFormData, setIsAuthenticated, setUsername } =
    useContext(Context);
  // const [uploadedFiles, setUploadedFiles] = useState([]); // New state for uploaded files
  const navigate = useNavigate();
  const [selected, setSelected] = useState("SA");
  // const [options, setOptions] = useState([]);

  // useEffect(() => {
  //     const countries = countryList().getData();
  //     setOptions(countries);
  // }, []);
  useEffect(() => {
    const storedSignupFormData = sessionStorage.getItem("signupFormData");
    if (storedSignupFormData) {
      setIsAuthenticated(true);
    }
  }, []);

  const onLogOut = () => {
    setIsAuthenticated(false);
    setUsername("");
    sessionStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("username"); // Correct typo
    navigate("/");
  };

  const [uploadedFiles, setUploadedFiles] = useState([
    {
      file: null, // Placeholder, as the file is not available initially
      url: defaultImageUrl,
    },
  ]); // Initial state with one default image

  const [error, setError] = useState(""); // State for error messages

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the first selected file (only one allowed)

    // Check if the file is an image
    if (file && file.type.includes("image")) {
      const fileObject = {
        file,
        url: URL.createObjectURL(file), // Generate a URL for the file
      };
      setUploadedFiles([fileObject]); // Replace the previous file with the new one
      setError(""); // Clear any previous error messages
    } else if (file && file.type.includes("pdf")) {
      setError(alert("PDFs are not allowed. Please upload an image.")); // Set an error message if a PDF is selected
      setUploadedFiles([]); // Clear the uploaded files
    } else {
      setError(alert("Invalid file type. Please upload an image.")); // Set a general error message for other file types
      setUploadedFiles([]); // Clear the uploaded files
    }
  };

  return (
    <div>
      <Navbar />

      <div className="auto-group-9asv-tpt" style={{ marginTop: "80px" }}>
        <div className="group-1261154818-4we">
          <div className="group-1261154816-bAt">
            <div className="outimage">
              {uploadedFiles.map((file, index) => (
                <div key={index} className="file-thumbnail position-relative">
                  <img
                    src={file.url}
                    alt="Thumbnail"
                    className="rectangle-39635-2X6"
                  />
                </div>
              ))}
              {error && (
                <div className=" poppins-medium zw_title_color ">{error}</div>
              )}{" "}
            </div>
            <div className="group-1261154816-bAt">
              <label htmlFor="file-upload" className="group-1261154824-yye">
                <img
                  className="group-1261154791-ZG8"
                  style={{ top: "-3rem" }}
                  src="/images/group-1261154791.png"
                  alt="upload-img"
                />
              </label>
              <input
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
            </div>

            <p className="muhammad-shiekh-7f2 poppins-regular zw_16 zw_text_fff">
              {username}
            </p>
          </div>

          <div className="group-1261154747-gLk">
            <p className="poppins-semibold zw_34 zw_text_fff">My ZWAARA</p>
            <div>
              <p className="poppins-regular zw_16 zw_text_fff">
                All your health related information is here. Your test results,
                summary of previous appointments, and medical reports You can
                review the summaries of yours and your dependents whenever you
                want in your Zwaara account.
              </p>
            </div>
            <div className="frame-1261154258-qVE">
              <div className="group-h1e poppins-medium zw_11">
                <Link to="/appointment">
                  <div className="group-dAC">
                    <img src="./images/vector-2KN.png" alt="" />
                    <div className="zw_000">Appointments</div>
                  </div>
                </Link>
                <img className="line-4-DeC" src="./images/Line 4.png" alt="" />
                <Link to="/prescription">
                  <div className="group-AJY">
                    <img src="./images/vector-6hS.png" alt="" />
                    <div className="zw_000">Prescription</div>
                  </div>
                </Link>
                <img className="line-4-DeC" src="./images/Line 4.png" alt="" />
                <Link to="/reports">
                  <div className="group-uQY">
                    <img src="./images/report-svgrepo-com-1.png" alt="" />
                    <div className="zw_000">Reports</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="auto-group-znxc-BB2">
        <div>
          <div className="group-1261154920-Uv8">
            <div className="auto-group-x1vc-RaU">
              <div className="frame-1261154254-xKW">
                <div>
                  <img
                    className="image-87-rvg"
                    src="/images/image-87.png"
                    alt=""
                  />
                </div>
                <div className="poppins-regular zw_16 zw_000">
                  <div>
                    zwaara is a licensed company by the Saudi Ministry of Health
                    with License
                  </div>
                  <div style={{ marginTop: "5px" }}>No: ***********</div>
                </div>
              </div>
            </div>
            <div className="group-1261154811-Mnt">
              <img
                className="group-e1J"
                src="/images/group-1261154759-for.png"
                alt=""
              />
              <div
                className="blood-donation poppins-medium zw_16 zw_000"
                data-bs-toggle="modal"
                data-bs-target="#BloodGroupBackdrop"
              >
                <div>Blood Donation</div>
                <div className="zw_title_color cursor-pointer">Update</div>
              </div>
              <div
                className="modal fade"
                id="BloodGroupBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content" style={{ width: "500px" }}>
                    <div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    <div className="modal-body" style={{ margin: "25px" }}>
                      <h3 style={{ marginBottom: "30px" }}>
                        {" "}
                        Select Blood Group
                      </h3>
                      <div>
                        <div className="profile_update">
                          <label className="profile_update_lable">
                            <input
                              type="radio"
                              name="option"
                              value="option1"
                              className="profile_update_input"
                            />
                            A+
                          </label>
                        </div>

                        <div className="profile_update">
                          <label className="profile_update_lable">
                            <input
                              type="radio"
                              name="option"
                              value="option2"
                              className="profile_update_input"
                            />
                            A-
                          </label>
                        </div>

                        <div className="profile_update">
                          <label className="profile_update_lable">
                            <input
                              type="radio"
                              name="option"
                              value="option3"
                              className="profile_update_input"
                            />
                            B+
                          </label>
                        </div>

                        <div className="profile_update">
                          <label className="profile_update_lable">
                            <input
                              type="radio"
                              name="option"
                              value="option4"
                              className="profile_update_input"
                            />
                            B-
                          </label>
                        </div>

                        <div className="profile_update">
                          <label className="profile_update_lable">
                            <input
                              type="radio"
                              name="option"
                              value="option5"
                              className="profile_update_input"
                            />
                            AB+
                          </label>
                        </div>

                        <div className="profile_update">
                          <label className="profile_update_lable">
                            <input
                              type="radio"
                              name="option"
                              value="option6"
                              className="profile_update_input"
                            />
                            AB-
                          </label>
                        </div>

                        <div className="profile_update">
                          <label className="profile_update_lable">
                            <input
                              type="radio"
                              name="option"
                              value="option7"
                              className="profile_update_input"
                            />
                            O+
                          </label>
                        </div>

                        <div className="profile_update">
                          <label className="profile_update_lable">
                            <input
                              type="radio"
                              name="option"
                              value="option8"
                              className="profile_update_input"
                            />
                            O-
                          </label>
                        </div>

                        <div className="profile_update">
                          <label className="profile_update_lable">
                            <input
                              type="radio"
                              name="option"
                              value="option9"
                              className="profile_update_input"
                            />
                            I don't know
                          </label>
                        </div>
                        <div
                          className="d-grid gap-2 col-12 mx-auto mt-3"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <button
                            type="button"
                            className="poppins-semibold zw_bg zw_text_fff border-0 rounded zw_16"
                            style={{ height: "30px", marginTop: "20px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-1261155016-Atk">
            <div className="frame-1261154262-5Vv">
              {/* <Link to="/records">
                                <div className="group-1261155539-RZn">
                                    <img className="group-1261154891-kc4" src="/images/group-1261154891-zd2.png" alt='' />
                                    <div className="poppins-regular zw_16 zw_text_color">Patients list</div>
                                </div>
                            </Link> */}
              <Link to="/patientlist">
                <div className="group-1261155539-RZn">
                  <img
                    className="group-1261154891-kc4"
                    src="/images/group-1261154891-zd2.png"
                    alt=""
                  />
                  <div className="poppins-regular zw_16 zw_text_color">
                    Patients list
                  </div>
                </div>
              </Link>
              <Link to="/addresslist">
                <div className="group-1261155539-RZn">
                  <img
                    className="group-1261154891-kc4"
                    src="/images/Addresslist.png"
                    alt=""
                  />
                  <div className="poppins-regular zw_16 zw_text_color">
                    Address list
                  </div>
                </div>
              </Link>
              <Link to="/wallet">
                <div className="group-1261155539-RZn">
                  <img
                    className="group-1261154891-kc4 text-black-filter"
                    src="/images/group-1261154895.png"
                    alt=""
                  />
                  <div className="poppins-regular zw_16 zw_text_color">
                    Wallet
                  </div>
                </div>
              </Link>
              <Link to="/mydocter">
                <div className="group-1261155539-RZn">
                  <img
                    className="group-1261154891-kc4"
                    src="/images/group-1261154894.png"
                    alt=""
                  />
                  <div className="poppins-regular zw_16 zw_text_color">
                    My Doctor
                  </div>
                </div>
              </Link>
              {/* <Link to="/savedcards">
                                <div className="group-1261155539-RZn">
                                    <img className="group-1261154891-kc4" src="/images/savecards.png" alt='' />
                                    <div className="poppins-regular zw_16 zw_text_color">Saved Cards</div>
                                </div>
                            </Link> */}
              {/* <div className="group-1261155539-RZn">
                <img
                  className="group-1261154891-kc4"
                  src="/images/order-svgrepo-com-1.png"
                  alt=""
                />
                <div className="poppins-regular zw_16 zw_text_color">
                  Doctor Orders
                </div>
              </div> */}
              <div
                className="group-1261155539-RZn hover-profile"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalcountry"
              >
                <img
                  className="group-1261154891-kc4"
                  src="/images/country.png"
                  alt=""
                />
                <div className="poppins-regular zw_16 zw_text_color">
                  Country
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModalcountry"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content overflow-visible">
                    <div className="modal-header border-0">
                      {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                      <button
                        class="sps-dialog-close regv2back"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="icon-close"></i>
                      </button>
                    </div>
                    <div className="modal-body border-0 mt-4">
                      <label
                        className="zw_poppins_regular poppins-regular zw_20 zw_text_111535"
                        htmlFor=""
                      >
                        Select Country
                      </label>
                      <div className="custom-flags-select">
                        <ReactFlagsSelect
                          selected={selected}
                          onSelect={(code) => setSelected(code)}
                          placeholder="Select Country"
                          searchable
                          searchPlaceholder="Search countries"
                          className="menu-flags"
                          selectedSize={20}
                          optionsSize={20}
                          selectButtonClassName="menu-flags-button"
                        />
                        {/* Custom arrow icon */}
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="custom-arrow-icon"
                        />
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <div
                        className="d-grid gap-2 col-12 mx-auto mt-3"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <button
                          className="poppins-regular zw_text_fff zw_bg_gradient border-0 rounded zw_20 py-3 my-2"
                          type="button"
                          style={{ marginTop: "20px" }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="group-1261155539-RZn cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#logoutBackdrop"
              >
                <img
                  className="group-1261154891-kc4"
                  src="/images/group-1261154887.png"
                  alt="group-1261154887"
                />
                <div className="poppins-regular  zw_16 zw_text_color">
                  Logout
                </div>
              </div>
              <div
                className="modal fade"
                id="logoutBackdrop"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ marginTop: "200px" }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>

                    <div
                      className="modal-body"
                      style={{
                        margin: "30px",
                        color: "#AF2245",
                        fontFamily: "poppins,sans-serif",
                      }}
                    >
                      <h2 className="poppins-semibold zw_20 zw_text_color">
                        Are you sure ,you want to logout ?
                      </h2>
                      <div
                        className="d-grid gap-2 d-md-block"
                        style={{ marginTop: "30px", marginRight: "5px" }}
                      >
                        {/* <button className="btn btn" type="button" style={{ width: "200px", borderColor: "#AF2245", marginRight: "10px", height: "40px", borderRadius: "10px", }} onMouseEnter={(e) => e.target.style.backgroundColor = "#AF2245"} onMouseLeave={(e) => e.target.style.backgroundColor = "transparent"} data-bs-dismiss="modal" aria-label="Close">Yes</button> */}
                        <button
                          className="poppins-semibold zw_18 border-0"
                          type="button"
                          style={{
                            width: "200px",
                            borderColor: "#AF2245",
                            height: "40px",
                            borderRadius: "10px",
                            color: "#000000",
                            marginRight: "10px",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#AF2245")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "transparent")
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={onLogOut}
                        >
                          Yes
                        </button>
                        <button
                          className="poppins-semibold zw_18 border-0"
                          type="button"
                          style={{
                            width: "200px",
                            borderColor: "#AF2245",
                            height: "40px",
                            borderRadius: "10px",
                            backgroundColor: "#AF2245",
                            color: "Background",
                          }}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-end">
            <Addrecord />
          </div>
          <div className="group-1261154921-tLL">
            <p className="text-right poppins-semibold zw_16 text zw_black mt-5">
              Medical Records
            </p>
            <div className="frame-1261154254-KwS">
              <div className="auto-group-dugu-Dmv">
                <img
                  className="group-1261154764-wxp"
                  src="./images/group-1261154764-8JQ.png"
                  alt=""
                />
                <div className="poppins-medium zw_15 zw_000">
                  0 Appointments
                </div>
              </div>
              <div className="auto-group-pbgg-bXa">
                <div className=" poppins-regular zw_13 zw_000">Firends</div>
                <div className="d-flex">
                  <img
                    className="group-5he"
                    src="/images/group-CbN.png"
                    alt=""
                  />
                  <div className="poppins-regular zw_13 zw_title_color mt-2">
                    0 Files
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-1261154254-KwS">
              <div className="auto-group-dugu-Dmv">
                <img
                  className="group-1261154764-wxp"
                  src="./images/group-1261154764-8JQ.png"
                  alt=""
                />
                <div className="poppins-medium zw_15 zw_000">
                  0 Appointments
                </div>
              </div>
              <div className="auto-group-pbgg-bXa">
                <div className="poppins-regular zw_13 zw_000">My Self</div>
                <div className="d-flex">
                  <img
                    className="group-5he"
                    src="/images/group-CbN.png"
                    alt=""
                  />
                  <div className="poppins-regular zw_13 zw_title_color mt-2">
                    0 Files
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="group-1000002072-oRE">
                    <div className="group-1261154099-WaY">
                        <img className="group-1261154093-SUC" src="./images/group-1261154093-jDn.png" />
                        <img className="group-1261154040-Nck" src="./images/group-1261154040-Dhv.png" />
                    </div>
                    <div className="auto-group-owcu-7KS">
                        <p className="company-FwS">Company</p>
                        <div className="frame-37135-bEc">
                            <p className="home-KwJ">Home</p>
                            <p className="about-g1A">About</p>
                            <p className="join-as-provider-dBJ">Join as provider</p>
                            <p className="get-care-Zqe">Get Care</p>
                            <p className="join-us-7sA">Join Us</p>
                        </div>
                    </div>
                    <div className="auto-group-waxv-Tw2">
                        <p className="laboratory-akk">Laboratory</p>
                        <div className="frame-37136-81a">
                            <p className="general-test-SY4">General test</p>
                            <p className="blood-test-nM2">Blood test</p>
                            <p className="urine-test-j1N">Urine test</p>
                            <p className="dna-test-55E">DNA Test</p>
                        </div>
                    </div>
                    <div className="auto-group-bf3a-pHi">
                        <p className="contact-us-AMa">Contact Us</p>
                        <p className="item-923489934721-Vue">+923489934721</p>
                        <p className="zwaarahealthcom-RoJ">Zwaara@health.com</p>
                        <p className="riadh-2343-saudi-arabia-Zec">Riadh 2343, Saudi Arabia,</p>
                    </div>
                </div> */}
      {/* </div> */}
      <Footer />
    </div>
  );
}

export default Profile;
