import React from 'react'
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import { Link, useNavigate } from 'react-router-dom';
import '../../Style/Caregivert1.css';
function Caregivertask1() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/selectlabs');
    };

    const caregiverTaskdata2 = [
        {
            image: require('../../assets/img/care_2.png'),
            title: 'Post-Operative Caregiver',
            subtitle: 'A post-operative caregiver provides care and support to a surgical patient, including assistance with daily activities, administering medication, monitoring recovery and in some cases coordinating overall healthcare. ',
            notehead: 'Includes',
            note: '** Does not include specialized nursing services such as injections.',
            noteimg: require('../../assets/img/radio Button.png')
        }
    ];
    return (
        <div>
            <Navbar />

            <div className="nursing-task-hRe">
            <div className='group-1261155501-1WC'>
                <div className=' d-flex align-items-center'>
                    <div>
                <Link to="/caregiver">
                        
                        <div className='d-flex gap-3 mx-4'>
                            <img src="./images/Group 1261154072.png" alt="" />
                            <p className='poppins-regular zw_16 zw_black mb-0 me-5'>Back</p>
                        </div>
                    </Link>
                </div>
                {/* <div className='w-100 mx-5' style={{ height:'100px',}}> */}
                    <div className='line_indicator_container'>
                        <div className='each_line_indicator active'>
                        </div>
                        <div className='each_line_indicator active'></div>
                        <div className='each_line_indicator '></div>
                        <div className='each_line_indicator'></div>
                    </div>
                </div>
                </div>
            </div>

            <div className="container mt-5 " >
                <div className="row">{
                     caregiverTaskdata2.map((item, index) => (
                        <div className="col-7" key={index}>
                        <div className="sa-info-light-bg  sa-caregiver-helper ">
                            <div className="d-flex">
                                <figure>
                                    <img src={item.image} alt="" style={{ width: "195px", height: "96px", borderRadius:'10px' }} />
                                </figure>
                                <h5 className='poppins-bold zw_24 zw_text_color ms-4'>{item.title}</h5>
                            </div>
                            <p className='px-4 poppins-regular zw_16 zw_secondary'>{item.subtitle}</p>
                           <div className='px-4 py-3 '>
                            <h6 className="poppins-bold zw_16 zw_title_color px-3 my-4" >{item.notehead}</h6>
                           <div className='d-flex'>
                           <img src={item.noteimg} alt="" style={{ width: "26px", height: "26px", }} />
                                {/* <label className="custom-radio">
                                        <input type="radio" name="radio" />
                                        <span className="checkmark"></span>
                                </label> */}
                                <p className='px-3  poppins-regular zw_16 zw_title_color'>{item.note}</p>
                           </div>
                           </div>
                        </div>
                    </div>
                    ))}
               
                   
                    <div className="col-5">
                        <h3 className="poppins-semibold zw-16 zw_text_color" style={{ marginLeft: "20px", }}>Selected test</h3>
                        <div className="" style={{ marginTop: "20px", marginLeft: "20px", padding:'15px', background:'#f6f7f9' ,borderRadius: "10px" }}>
                            <h3 className=" poppins-semibold zw-16 zw_text_color" >
                                1 Week - 12 Hours
                            </h3>
                            <button className="sa-select-durationn zw_bg my-3 poppins-regular zw_14 zw_text_fff border-0" style={{  padding: "15px", borderRadius: "5px" }} onClick={handleButtonClick}>
                            Start from SAR 3000
                            </button>
                        </div>
                        <div className="" style={{ marginTop: "20px", marginLeft: "20px", padding:'15px', background:'#f6f7f9' ,borderRadius: "10px" }}>
                            <h3 className=" poppins-semibold zw-16 zw_text_color" >
                                2 Weeks - 12 Hours
                            </h3>
                            <button className="sa-select-durationn zw_bg my-3 poppins-regular zw_14 zw_text_fff border-0" style={{  padding: "15px", borderRadius: "5px" }} onClick={handleButtonClick}>
                            Start from SAR 3000
                            </button>
                        </div>
                        <div className="" style={{ marginTop: "20px", marginLeft: "20px", padding:'15px', background:'#f6f7f9' ,borderRadius: "10px" }}>
                            <h3 className=" poppins-semibold zw-16 zw_text_color" >
                                3 Weeks - 12 Hours
                            </h3>
                            <button className="sa-select-durationn zw_bg my-3 poppins-regular zw_14 zw_text_fff border-0" style={{  padding: "15px", borderRadius: "5px" }} onClick={handleButtonClick}>
                            Start from SAR 3000
                            </button>
                        </div>
                        <div className="" style={{ marginTop: "20px", marginLeft: "20px", padding:'15px', background:'#f6f7f9' ,borderRadius: "10px" }}>
                            <h3 className=" poppins-semibold zw-16 zw_text_color" >
                                4 Weeks - 12 Hours
                            </h3>
                            <button className="sa-select-durationn zw_bg my-3 poppins-regular zw_14 zw_text_fff border-0" style={{  padding: "15px", borderRadius: "5px" }} onClick={handleButtonClick}>
                            Start from SAR 3000
                            </button>
                        </div>
                    </div>
                </div>
                <br />
            </div>
            <Footer />
        </div>
    )
}

export default Caregivertask1
