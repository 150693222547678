import React from 'react'
import Navbar from '../Layout/Navbar'
import Footer from '../Layout/Footer'
import '../../Style/join_a_p.css';
import { Link } from 'react-router-dom';

function JoinAP() {
    return (
        <div>
            <Navbar />
            <div className="join-as-provider slider_pt">
                <div className="frame-1261154266">
                    <div className="banner-container-text poppins-semibold zw_46">
                        Participate In Facilitating Access To Medical Services In Modern And Flexible Ways With Zuwara
                    </div>

                    <div className="container-17">
                        <div className="group-1261155553">
                            <img src="/images/Ellipse1000.png" alt="" />
                            <img src="/images/Ellipse1001.png" className='Ellipse-img' alt="" />
                            <img src="/images/Ellipse1002.png" className='Ellipse-img' alt="" />
                            <img src="/images/Ellipse1003.png" className='Ellipse-img' alt="" />
                            <img src="/images/Ellipse1004.png" className='Ellipse-img' alt="" />
                            <img src="/images/Ellipse1005.png" className='Ellipse-img' alt="" />

                        </div>
                        <div className="trusted-by-the-top-health-cares-of-the-country poppins-medium zw_18 zw_text_color">
                            Trusted by the top Health-cares of the country
                        </div>
                    </div>
                </div>

                <div className='how-to-join'>
                    <div className="how-to-join-as-aprovider poppins-semibold zw_46">
                        How To Join As A Provider
                    </div>

                    <div className="container-3">
                        <div className="group-1261155556">
                            <img className="undraw-approve-qwp-71" src="/images/undraw_approve_qwp71.png" />
                            <div className="img-sub-title poppins-medium zw_18">
                                Fill up the form and submit your data
                            </div>
                        </div>
                        <img className="vector-1" src="/images/Vector 1-1.svg" />
                        <div className="group-1261155556">
                            <img className="undraw-approve-qwp-71" src="/images/undraw-online.png" />
                            <div className="img-sub-title poppins-medium zw_18">
                                Zuwara team will contact with you
                            </div>
                        </div>
                        <img className="vector-1" src="/images/Vector 1-2.svg" />
                        <div className="group-1261155556">
                            <img className="undraw-approve-qwp-71" src="/images/undraw-job.png" />
                            <div className="img-sub-title poppins-medium zw_18">
                                Start receiving orders more widely
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-18">
                    <div className="container-7">
                        <div className="heading-4 poppins-semibold zw_34 zw_text_color">
                            Advantages Of Joining
                        </div>
                        <div className="heading-2 poppins-semibold zw_46 zw_title_color">
                            For Doctors & Specialists
                        </div>
                        <div className="doctor-specialist-cont poppins-semibold zw_16 zw_secondary">
                            Lorem ipsum dolor sit amet consectetur. Urna eu risus hac augue sodales at. Et tellus massa pretium laoreet cursus. Tellus urna proin elementum odio. Ac luctus.
                        </div>
                        <div className="group-1261155567">
                            <div className="clinic-house-hospital-svgrepo-com-1">
                                <img src="images/Group 1261155561.png" />
                            </div>
                            <div className="group-1261155559">
                                <div className="heading-5 poppins-medium zw_18">
                                    Virtual Clinic
                                </div>
                                <span className="poppins-semibold zw_16 zw_secondary">
                                    Lorem ipsum dolor sit amet consectetur. Lobortis sed hendrerit quis arcu erat lacus sollicitudin. Feugiat.
                                </span>
                            </div>
                        </div>
                        <div className="group-1261155567">
                            <div className="clinic-house-hospital-svgrepo-com-1">
                                <img src="images/social-logo-svgrepo-com 1.png" />
                            </div>
                            <div>
                                <div className="heading-5 poppins-medium zw_18">
                                    Digital Pressence
                                </div>
                                <span className="poppins-semibold zw_16 zw_secondary">
                                    Lorem ipsum dolor sit amet consectetur. Lobortis sed hendrerit quis arcu erat lacus sollicitudin. Feugiat.
                                </span>
                            </div>
                        </div>

                        <div className="group-1261155567">
                            <div className="clinic-house-hospital-svgrepo-com-1">
                                <img src="images/clinic-house-hospital-svgrepo-com 3.png" />
                            </div>
                            <div className="group-1261155559">
                                <div className="heading-5 poppins-medium zw_18">
                                    Additional Income
                                </div>
                                <span className="poppins-semibold zw_16 zw_secondary">
                                    Lorem ipsum dolor sit amet consectetur. Lobortis sed hendrerit quis arcu erat lacus sollicitudin. Feugiat.
                                </span>
                                <Link to='/joinbutton' className='joinBtnCont'>
                                    <button className="group-1261155550 mt-5 poppins-medium zw_18 zw_text_fff d-flex justify-content-center align-items-center">
                                        Join Now
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="figure-care-provider-doctors-section-png">
                        <img className="rectangle-39711" src="/images/doc-fem-team.png" />
                        <img className='image-text-container' src="/images/Rectangle450doc.png" alt="vv" />
                        <p className='image-text-pro poppins-regular zw_text_fff zw_12'><b>450 +</b> Doctors</p>
                        <img className='image-text-container-1' src="/images/Rectangle39711clinic.png" alt="vv" />
                        <p className='image-text-pro-2 poppins-regular zw_title_color zw_14'><b>450 +</b> Clinic</p>
                        <div className='image-text-container-2'>
                            <p className='image-text-pro-3 poppins-regular zw_title_color zw_14'><b>40 +</b> <br />Superficiality</p>
                        </div>
                    </div>
                </div>

                <div className="container-14">
                    <div className='container-14-img'>
                        <div className='container-14-img-left'>
                            <div>
                                <img src="/images/Rectangle 39715.png" style={{ marginBottom: '2.3rem' }} alt="" />
                            </div>
                            <div>
                                <img src="/images/Rectangle 39716.png" alt="" />
                            </div>
                        </div>
                        <div>
                            <img src="/images/Rectangle 39717.png" alt="" />
                        </div>
                    </div>

                    <div className="group-1261155576">
                        <div className="heading-41 poppins-semibold zw_34 zw_text_color">
                            Advantages Of Joining
                        </div>
                        <div className="heading-21 poppins-semibold zw_46 zw_title_color">
                            For Hospitals &amp; Medical Centers
                        </div>
                        <div className="medical-centers-text poppins-semibold zw_16 zw_secondary">
                            Lorem ipsum dolor sit amet consectetur. Urna eu risus hac augue sodales at. Et tellus massa pretium laoreet cursus. Tellus urna proin elementum odio. Ac luctus.
                        </div>
                        <div className="group-1261155574">
                            <img className="group-1" src="/images/Manageappointmentsimg.png" />
                            <div className="group-1261155560">
                                <div className="heading-53 poppins-medium zw_18 zw_002a56">
                                    Manage appointments easily
                                </div>
                                <span className="poppins-semibold zw_16 zw_secondary">
                                    Lorem ipsum dolor sit amet consectetur. Lobortis sed hendrerit quis arcu erat lacus sollicitudin. Feugiat.
                                </span>
                            </div>
                        </div>
                        <div className="group-1261155574">
                            <img className="group-3" src="/images/Serving-img.png" />
                            <div className="group-1261155560">
                                <div className="heading-53 poppins-medium zw_18 zw_002a56">
                                    Serving a wider segment
                                </div>
                                <span className="poppins-semibold zw_16 zw_secondary">
                                    Lorem ipsum dolor sit amet consectetur. Fermentum arcu eget justo morbi est consectetur tellus iaculis vitae.
                                </span>
                            </div>
                        </div>

                        <div className="group-1261155574">
                            <img className="group-1" src="/images/Integrated-img.png" />
                            <div className="group-1261155560">
                                <div className="heading-53 poppins-medium zw_18 zw_002a56">
                                    Integrated professional services
                                </div>
                                <span className="poppins-semibold zw_16 zw_secondary">
                                    Lorem ipsum dolor sit amet consectetur. Fermentum arcu eget justo morbi est consectetur tellus iaculis vitae.
                                </span>
                            </div>
                        </div>
                        <Link to='/joinbutton' className='joinBtnCont'>
                            <div className='btn-join'>
                                <button className="group-1261155563 poppins-medium zw_18 zw_text_fff">Join Now</button>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="frame-12611542671">
                    <div className='zwaara-specification'>
                        <div className='Booking-details'>
                            <div className='img-line-mr' >
                                <div className='poppins-semibold zw_46 zw_title_color'>388247+</div>
                                <div className='poppins-semibold zw_16 zw_black'>Bookings</div>
                            </div>
                            <div>
                                <img src="/images/Line 8.png" alt="" />
                            </div>
                        </div>
                        <div>
                            <div className='Booking-details'>
                                <div className='img-line-mr' >
                                    <div className='poppins-semibold zw_46 zw_title_color'>2373+</div>
                                    <div className='poppins-semibold zw_16 zw_black'>Service Providers</div>
                                </div>
                                <div style={{ width: '0' }}>
                                    <img src="/images/Line 8.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div >
                            <div className='poppins-semibold zw_46 zw_title_color'>213+</div>
                            <div className='poppins-semibold zw_16 zw_black'>Doctors</div>
                        </div>
                    </div>
                    <div className="container-2">
                        <div className="group-1261155577">
                            <div className="join-zawaara-now poppins-semibold zw_34 zw_black">
                                Join Zuwara Now
                            </div>
                            <div className="poppins-medium zw_18 zw_black">
                                To provide your medical services and enjoy innovative benefits
                            </div>
                        </div>
                        <div className="group-1261155608">
                            <div className="request-sent-svgrepo-com-1">
                                <img src="/images/request-sent-svgrepo-com 1.png" alt=''/>
                            </div>
                            <Link to="/request">
                            <div className="poppins-semibold zw_16 zw_text_fff">
                                Request join as service provider
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default JoinAP